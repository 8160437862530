// src/pages/Home.js

import React from 'react';
import { Typography, Container, Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  boxShadow: theme.shadows[3],
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(2),
}));

const Home = () => {
  return (
    <StyledContainer component="main">
      <StyledTypography component="h1" variant="h4">
        管理画面へようこそ
      </StyledTypography>
      <StyledPaper elevation={3}>
        <Typography variant="h6" gutterBottom>
          ダッシュボード
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Paper
              elevation={3}
              style={{
                padding: '20px',
                textAlign: 'center',
                backgroundColor: '#f5f5f5',
              }}
            >
              <Typography variant="h6">ユーザリスト</Typography>
              <Typography>ユーザの管理はこちらから</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper
              elevation={3}
              style={{
                padding: '20px',
                textAlign: 'center',
                backgroundColor: '#f5f5f5',
              }}
            >
              <Typography variant="h6">コードリスト</Typography>
              <Typography>コードの管理はこちらから</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper
              elevation={3}
              style={{
                padding: '20px',
                textAlign: 'center',
                backgroundColor: '#f5f5f5',
              }}
            >
              <Typography variant="h6">ログ確認</Typography>
              <Typography>ログの確認はこちらから</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper
              elevation={3}
              style={{
                padding: '20px',
                textAlign: 'center',
                backgroundColor: '#f5f5f5',
              }}
            >
              <Typography variant="h6">クーポン生成</Typography>
              <Typography>クーポンの生成はこちらから</Typography>
            </Paper>
          </Grid>
        </Grid>
      </StyledPaper>
    </StyledContainer>
  );
};

export default Home;

