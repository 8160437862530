// src/pages/BudgetManagement.js

import React, { useEffect, useState } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Container, Typography, CssBaseline } from '@mui/material';
import { getBudgetList, addBudget } from '../utils/api';
import { styled } from '@mui/material/styles';

// 数値を3桁ごとにカンマ区切りでフォーマットする関数
const formatNumber = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: '#000000',
  '& .MuiTableCell-head': {
    color: '#ffffff',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme, index }) => ({
  backgroundColor: index % 2 === 0 ? '#f5f5f5' : '#ffffff',
}));

const GreenButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#4caf50',
  color: '#ffffff',
  '&:hover': {
    backgroundColor: '#45a049',
  },
}));

const DialogContentCustom = styled(DialogContent)(({ theme }) => ({
  width: '800px', // ダイアログの横幅を2倍に設定
}));

const BudgetManagement = () => {
  const [budgets, setBudgets] = useState([]);
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState('');
  const [selectedPublisherId, setSelectedPublisherId] = useState(null);

  useEffect(() => {
    const fetchBudgetList = async () => {
      try {
        const response = await getBudgetList();
        setBudgets(response.data);
      } catch (error) {
        console.error('Error fetching budget list:', error);
      }
    };

    fetchBudgetList();
  }, []);

  const handleClickOpen = (publisherId) => {
    setSelectedPublisherId(publisherId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAmount('');
    setSelectedPublisherId(null);
  };

  const handleAddBudget = async () => {
    if (selectedPublisherId !== null) {
      try {
        await addBudget(selectedPublisherId, parseInt(amount, 10));
        const response = await getBudgetList();
        setBudgets(response.data);
        handleClose();
      } catch (error) {
        console.error('Error adding budget:', error);
      }
    }
  };

  return (
    <Container component="main">
      <CssBaseline />
      <Typography component="h1" variant="h5" gutterBottom style={{ fontWeight: 'bold', marginBottom: '16px' }}>
        予算管理
      </Typography>
      <StyledTableContainer component={Paper}>
        <Table>
          <StyledTableHead>
            <TableRow>
              <TableCell>Publisher ID</TableCell>
              <TableCell>ユーザー名</TableCell>
              <TableCell>追加総額</TableCell>
              <TableCell>消費総額</TableCell>
              <TableCell>残予算</TableCell>
              <TableCell>アクション</TableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {budgets.map((budget, index) => (
              <StyledTableRow key={budget.publisherId} index={index}>
                <TableCell>{budget.publisherId}</TableCell>
                <TableCell>{budget.username}</TableCell>
                <TableCell>{formatNumber(budget.totalAdditions)}</TableCell>
                <TableCell>{formatNumber(budget.totalConsumptions)}</TableCell>
                <TableCell>{formatNumber(budget.remainingBudget)}</TableCell>
                <TableCell>
                  <GreenButton variant="contained" onClick={() => handleClickOpen(budget.publisherId)}>
                    予算追加
                  </GreenButton>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>予算追加</DialogTitle>
        <DialogContentCustom>
          <TextField
            autoFocus
            margin="dense"
            label="追加金額"
            type="number"
            fullWidth
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <Typography variant="body2" color="textSecondary" style={{ marginTop: '8px' }}>
            ※負の値を指定すると減算できます
          </Typography>
        </DialogContentCustom>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            キャンセル
          </Button>
          <GreenButton onClick={handleAddBudget}>
            追加
          </GreenButton>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default BudgetManagement;

