import React, { useEffect, useState } from 'react';
import { Container, Typography, CssBaseline, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { getCoupons, generateCoupon } from '../utils/api.js';
import { styled } from '@mui/material/styles';

const ScrollableContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
  overflowX: 'auto',
  width: '100%',
}));

const GreenButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#4caf50',
  color: '#ffffff',
  '&:hover': {
    backgroundColor: '#45a049',
  },
}));

const TstCouponList = () => {
  const [coupons, setCoupons] = useState([]);
  const [filteredCoupons, setFilteredCoupons] = useState([]);

  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const response = await getCoupons();
        const allCoupons = response.data.data;

        // シンボルが "TST" のクーポンのみをフィルタ
	const tstCoupons = allCoupons.filter(coupon => coupon.symbol === 'TST' && coupon.status === 'NEW');
        setCoupons(tstCoupons);
        setFilteredCoupons(tstCoupons);
      } catch (error) {
        console.error('Error fetching coupons:', error);
        setCoupons([]);
        setFilteredCoupons([]);
      }
    };

    fetchCoupons();
  }, []);

  const handleGenerateCoupon = async () => {
    try {
      const response = await generateCoupon('TST', 1, 'TEST', 123);
      console.log('Coupon generated:', response.data);
      // 新しいクーポンを生成後にリストを再取得する場合
      const fetchCoupons = async () => {
        const response = await getCoupons();
        const allCoupons = response.data.data;
	const tstCoupons = allCoupons.filter(coupon => coupon.symbol === 'TST' && coupon.status === 'NEW');
        setCoupons(tstCoupons);
        setFilteredCoupons(tstCoupons);
      };
      fetchCoupons();
    } catch (error) {
      console.error('Error generating coupon:', error);
    }
  };

  return (
    <Container component="main">
      <CssBaseline />
      <Typography component="h1" variant="h5" gutterBottom>
        テストコードリスト
      </Typography>
      <Typography variant="body1">
        {filteredCoupons.length} / {coupons.length}
      </Typography>
      <GreenButton variant="contained" color="primary" onClick={handleGenerateCoupon} style={{ marginTop: '10px', marginBottom: '20px' }}>
        テストコード生成
      </GreenButton>
      <ScrollableContainer>
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: '#000', color: '#fff' }}>
              <TableRow>
                <TableCell style={{ color: '#fff', minWidth: '150px' }}>Code</TableCell>
                <TableCell style={{ color: '#fff', minWidth: '80px' }}>Status</TableCell>
                <TableCell style={{ color: '#fff', minWidth: '80px' }}>Symbol</TableCell>
                <TableCell style={{ color: '#fff', minWidth: '80px' }}>Seed</TableCell>
                <TableCell style={{ color: '#fff', minWidth: '80px' }}>Egg ID</TableCell>
                <TableCell style={{ color: '#fff', minWidth: '80px' }}>Pub ID</TableCell>
                <TableCell style={{ color: '#fff', minWidth: '150px' }}>Beneficiary</TableCell>
                <TableCell style={{ color: '#fff', minWidth: '150px' }}>Mana Value</TableCell>
                <TableCell style={{ color: '#fff', minWidth: '300px' }}>Mana Info</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCoupons.length > 0 && filteredCoupons.map((coupon) => (
                <TableRow key={coupon.couponCode} style={{ backgroundColor: coupon.id % 2 ? '#f5f5f5' : 'white' }}>
                  <TableCell>{coupon.couponCode}</TableCell>
                  <TableCell>{coupon.status}</TableCell>
                  <TableCell>{coupon.symbol}</TableCell>
                  <TableCell>{coupon.seed}</TableCell>
                  <TableCell>{coupon.eggId}</TableCell>
                  <TableCell>{coupon.publisherId}</TableCell>
                  <TableCell>{coupon.beneficiary}</TableCell>
                  <TableCell>{coupon.manaValue}</TableCell>
                  <TableCell>{coupon.manaInfo}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ScrollableContainer>
    </Container>
  );
};

export default TstCouponList;

