// src/context/AuthContext.js
import React, { createContext, useContext, useState } from 'react';
import { loginUser } from '../utils/api';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const login = async (username, password) => {
    const response = await loginUser(username, password);
    localStorage.setItem('accessToken', response.data.token);
    setUser({ username });
  };

  const value = {
    user,
    login,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

