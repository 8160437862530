import React from 'react';
import { Drawer, List, ListItem, ListItemText, ListItemIcon, Toolbar, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ListIcon from '@mui/icons-material/List';
import AddIcon from '@mui/icons-material/Add';
import LogIcon from '@mui/icons-material/Assignment';
import CodeIcon from '@mui/icons-material/Code';
import ExitToAppIcon from '@mui/icons-material/ExitToApp'; // サインアウト用アイコンをインポート
import MoneyIcon from '@mui/icons-material/AttachMoney'; // 予算管理用アイコンをインポート
import { useNavigate } from 'react-router-dom';

const drawerWidth = 240;

const Sidebar = ({ open, handleDrawerClose }) => {
  const navigate = useNavigate();

  const handleSignOut = () => {
    // ログアウト処理（トークンの削除など）
    localStorage.removeItem('accessToken'); // ローカルストレージからトークンを削除
    navigate('/login'); // ログイン画面にリダイレクト
  };

  const menuItems = [
    { text: 'ユーザリスト', icon: <ListIcon />, path: '/user-list' },
    { text: 'コードリスト', icon: <ListIcon />, path: '/coupon-list' },
    { text: 'コード生成', icon: <AddIcon />, path: '/coupon-generator' },
    { text: 'リクエストログ', icon: <LogIcon />, path: '/logs' },
    { text: 'テストコード一覧', icon: <CodeIcon />, path: '/tst-coupon-list' },
    { text: '予算管理', icon: <MoneyIcon />, path: '/budget-management' }, // 予算管理ページリンクを追加
    { text: 'サインアウト', icon: <ExitToAppIcon />, action: handleSignOut } // サインアウトメニューを追加
  ];

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <Toolbar>
        <Typography variant="h6">コードD管理画面</Typography>
      </Toolbar>
      <List>
        {menuItems.map((item, index) => (
          <ListItem
            button
            key={index}
            onClick={item.action ? item.action : () => navigate(item.path)} // actionがある場合はそれを実行
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;

