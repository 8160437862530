import React, { useState } from 'react';
import { Container, Typography, CssBaseline, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getCoupons, generateCoupon } from '../utils/api';
import Papa from 'papaparse';

const ScrollableContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
  overflowX: 'auto',
  width: '100%',
}));

const CouponGeneration = () => {
  const [records, setRecords] = useState([]);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const handleFileUpload = (event) => {
    setError('');
    setMessage('');
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: (result) => {
          const { data, errors } = result;
          let failedCount = 0;

          const validRecords = data.map((record) => {
            const manaValue = parseInt(record.manaValue); 
            const seed = parseInt(record.seed); 
            if (record.symbol && !isNaN(seed) && !isNaN(manaValue) && record.manaInfo) {
              return {
                symbol: record.symbol,
                seed: seed,
                manaValue: manaValue, 
                manaInfo: record.manaInfo,
                status: '未生成',
                duplicationStatus: '未チェック', // 重複判定ステータス
                isProcessing: false, // 生成中フラグ
                couponCode: '', // 生成されたクーポンコードを保存
                url: '' // 生成されたクーポンのURLを保存
              };
            } else {
              failedCount++;
              return null; // 読み込み失敗としてカウント
            }
          }).filter(record => record !== null);

          if (errors.length > 0 || failedCount > 0) {
            setError(`${errors.length + failedCount} レコードの読み込みに失敗しました`);
            setRecords(validRecords);
          } else {
            setRecords(validRecords);
            setMessage(`${validRecords.length} レコードを読み込みました`);
          }
        },
      });
    }
  };

  const handleCheckDuplicates = async () => {
    try {
      const response = await getCoupons();
      const existingCoupons = response.data.data;

      const updatedRecords = records.map(record => {
        const isDuplicate = existingCoupons.some(coupon =>
          coupon.symbol === record.symbol &&
          coupon.seed === record.seed &&
          coupon.manaValue === record.manaValue
        );
        return {
          ...record,
          duplicationStatus: isDuplicate ? '重複あり' : '重複無し',
        };
      });

      setRecords(updatedRecords);
    } catch (error) {
      setError('重複チェック中にエラーが発生しました');
    }
  };

  const handleGenerateCodes = async () => {
    for (let i = 0; i < records.length; i++) {
      const record = records[i];
      if (record.duplicationStatus === '未チェック' || record.duplicationStatus === '重複あり') {
        continue; // 未チェックまたは重複ありのレコードは生成しない
      }

      records[i].isProcessing = true; // 生成中フラグを立てる
      setRecords([...records]);

      try {
        const response = await generateCoupon(record.symbol, record.seed, record.manaInfo, record.manaValue);
        if (response.status === 201) {
          records[i].status = '生成完了';
          records[i].couponCode = response.data.couponCode; // 生成されたクーポンコードを保存
          records[i].url = response.data.url; // 生成されたURLを保存
        } else {
          records[i].status = '生成失敗';
        }
      } catch (error) {
        records[i].status = '生成失敗';
      }

      records[i].isProcessing = false; // 生成中フラグを下げる
      setRecords([...records]); // リストを更新して表示
    }
  };

  const handleForceGenerateCodes = async () => {
    for (let i = 0; i < records.length; i++) {
      const record = records[i];

      records[i].isProcessing = true; // 生成中フラグを立てる
      setRecords([...records]);

      try {
        const response = await generateCoupon(record.symbol, record.seed, record.manaInfo, record.manaValue);
        if (response.status === 201) {
          records[i].status = '生成完了';
          records[i].couponCode = response.data.couponCode; // 生成されたクーポンコードを保存
          records[i].url = response.data.url; // 生成されたURLを保存
        } else {
          records[i].status = '生成失敗';
        }
      } catch (error) {
        records[i].status = '生成失敗';
      }

      records[i].isProcessing = false; // 生成中フラグを下げる
      setRecords([...records]); // リストを更新して表示
    }
  };

  const handleExportResults = () => {
    const exportData = records
      .filter(record => record.status === '生成完了')
      .map(record => ({
        couponCode: record.couponCode,
        url: record.url,
        symbol: record.symbol,
        seed: record.seed,
        manaValue: record.manaValue,
        manaInfo: record.manaInfo,
      }));

    const csv = Papa.unparse(exportData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'coupon_results.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container component="main">
      <CssBaseline />
      <Typography component="h1" variant="h5" gutterBottom>
        コード生成
      </Typography>

      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        type="file"
        inputProps={{ accept: '.csv' }}
        onChange={handleFileUpload}
      />

      {error && <Typography color="error">{error}</Typography>}
      {message && <Typography color="primary">{message}</Typography>}

      {records.length > 0 && (
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
          <Button variant="contained" color="primary" onClick={handleCheckDuplicates} style={{ marginRight: '10px' }}>
            重複チェック
          </Button>
          <Button variant="contained" color="primary" onClick={handleGenerateCodes} style={{ marginRight: '10px' }}>
            コード生成
          </Button>
          <Button variant="contained" color="secondary" onClick={handleForceGenerateCodes}>
            強制生成
          </Button>
        </div>
      )}

      {records.length > 0 && (
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
          <Button variant="contained" color="primary" onClick={handleExportResults}>
            結果エクスポート
          </Button>
        </div>
      )}

      <ScrollableContainer>
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: '#000', color: '#fff' }}>
              <TableRow>
                <TableCell style={{ color: '#fff', minWidth: '150px' }}>Symbol</TableCell>
                <TableCell style={{ color: '#fff', minWidth: '100px' }}>Seed</TableCell>
                <TableCell style={{ color: '#fff', minWidth: '100px' }}>Mana Value</TableCell>
                <TableCell style={{ color: '#fff', minWidth: '200px' }}>Mana Info</TableCell>
                <TableCell style={{ color: '#fff', minWidth: '150px' }}>生成ステータス</TableCell>
                <TableCell style={{ color: '#fff', minWidth: '150px' }}>重複判定</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {records.length > 0 && records.map((record, index) => (
                <TableRow
                  key={index}
                  style={{
                    backgroundColor: record.isProcessing ? '#f0e68c' : index % 2 ? '#f5f5f5' : 'white',
                  }}
                >
                  <TableCell>{record.symbol}</TableCell>
                  <TableCell>{record.seed}</TableCell>
                  <TableCell>{record.manaValue}</TableCell>
                  <TableCell>{record.manaInfo}</TableCell>
                  <TableCell style={{
                    color: record.status === '生成完了' ? 'green' :
                           record.status === '生成失敗' ? 'red' :
                           'blue'
                  }}>
                    {record.status}
                  </TableCell>
                  <TableCell style={{
                    color: record.duplicationStatus === '重複あり' ? 'red' :
                           record.duplicationStatus === '重複無し' ? 'green' :
                           'blue'
                  }}>
                    {record.duplicationStatus}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ScrollableContainer>
    </Container>
  );
};

export default CouponGeneration;

