// src/pages/UserList.js

import React, { useEffect, useState } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Container, Typography, CssBaseline } from '@mui/material';
import { getUsers, registerUser } from '../utils/api';
import { styled } from '@mui/material/styles';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: '#000000',
  '& .MuiTableCell-head': {
    color: '#ffffff',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme, index }) => ({
  backgroundColor: index % 2 === 0 ? '#f5f5f5' : '#ffffff',
}));

const GreenButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#4caf50',
  color: '#ffffff',
  '&:hover': {
    backgroundColor: '#45a049',
  },
}));

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [username, setUsername] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await getUsers();
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setUsername('');
  };

  const handleCreateUser = async () => {
    try {
      await registerUser(username);
      const response = await getUsers();
      setUsers(response.data);
      handleClose();
    } catch (error) {
      console.error('Error creating user:', error);
    }
  };

  return (
    <Container component="main">
      <CssBaseline />
      <Typography component="h1" variant="h5" gutterBottom style={{ fontWeight: 'bold', marginBottom: '16px' }}>
        ユーザリスト
      </Typography>
      <GreenButton variant="contained" onClick={handleClickOpen}>
        新規ユーザ作成
      </GreenButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>新規ユーザーを作成</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="ユーザー名"
            type="text"
            fullWidth
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            キャンセル
          </Button>
          <GreenButton onClick={handleCreateUser}>
            作成
          </GreenButton>
        </DialogActions>
      </Dialog>
      <StyledTableContainer component={Paper}>
        <Table>
          <StyledTableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>ユーザー名</TableCell>
              <TableCell>権限</TableCell>
              <TableCell>APIキー</TableCell>
              <TableCell>作成日</TableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {users.map((user, index) => (
              <StyledTableRow key={user.id} index={index}>
                <TableCell>{user.id}</TableCell>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.role}</TableCell>
                <TableCell>{user.apiKey}</TableCell>
                <TableCell>{user.createdAt}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Container>
  );
};

export default UserList;

