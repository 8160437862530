import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Login from './pages/Login';
import Home from './pages/Home';
import UserList from './pages/UserList';
import CouponList from './pages/CouponList';
import Log from './pages/RequsetLogList';
import CouponGeneration from './pages/CouponGeneration';
import TstCouponList from './pages/TstCouponList'; 
import Layout from './components/Layout';
import BudgetManagement from './pages/BudgetManagement'; // BudgetManagementのインポート

function App() {
  return (
    <AuthProvider>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/home"
            element={
              <Layout>
                <Home />
              </Layout>
            }
          />
          <Route
            path="/user-list"
            element={
              <Layout>
                <UserList />
              </Layout>
            }
          />
          <Route
            path="/coupon-list"
            element={
              <Layout>
                <CouponList />
              </Layout>
            }
          />
          <Route
            path="/coupon-generator"
            element={
              <Layout>
                <CouponGeneration />
              </Layout>
            }
          />
          <Route
            path="/logs"
            element={
              <Layout>
                <Log />
              </Layout>
            }
          />
          <Route
            path="/tst-coupon-list"
            element={
              <Layout>
                <TstCouponList />
              </Layout>
            }
          />
          <Route
            path="/budget-management"
            element={
              <Layout>
                <BudgetManagement /> 
              </Layout>
            }
          />
        </Routes>
    </AuthProvider>
  );
}

export default App;

