import React, { useEffect, useState } from 'react';
import { TextField, Container, Typography, CssBaseline, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { getCoupons } from '../utils/api';
import { styled } from '@mui/material/styles';

const ScrollableContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
  overflowX: 'auto',  // Enable horizontal scrolling
  width: '100%',  // Ensure the container takes up full width
}));

const CouponList = () => {
  const [coupons, setCoupons] = useState([]);
  const [filteredCoupons, setFilteredCoupons] = useState([]);
  const [filter, setFilter] = useState('');
  const [visibleCoupons, setVisibleCoupons] = useState({});  // State to track visibility of coupon codes

  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const response = await getCoupons();
        setCoupons(response.data.data);
        setFilteredCoupons(response.data.data);
      } catch (error) {
        console.error('Error fetching coupons:', error);
        setCoupons([]);
        setFilteredCoupons([]);
      }
    };

    fetchCoupons();
  }, []);

  const handleFilterChange = (event) => {
    const keyword = event.target.value;
    setFilter(keyword);

    const filtered = coupons.filter((coupon) => {
      return (
        (coupon.couponCode && coupon.couponCode.includes(keyword)) ||
        (coupon.beneficiary && coupon.beneficiary.includes(keyword)) ||
        (coupon.symbol && coupon.symbol.includes(keyword)) ||
        (coupon.eggId && coupon.eggId.toString().includes(keyword)) ||
        (coupon.seed && coupon.seed.toString().includes(keyword)) ||
        (coupon.manaValue && coupon.manaValue.toString().includes(keyword))
      );
    });

    setFilteredCoupons(filtered);
  };

  const toggleCouponVisibility = (couponCode) => {
    setVisibleCoupons((prev) => ({
      ...prev,
      [couponCode]: !prev[couponCode],
    }));
  };

  const maskCouponCode = (couponCode) => {
    return couponCode.slice(0, -4) + '****';
  };

  return (
    <Container component="main">
      <CssBaseline />
      <Typography component="h1" variant="h5" gutterBottom>
        コードリスト
      </Typography>
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        id="filter"
        label="検索"
        name="filter"
        autoComplete="filter"
        value={filter}
        onChange={handleFilterChange}
      />
      <Typography variant="body1">
	{filteredCoupons.length} / {coupons.length}
      </Typography>
      <ScrollableContainer>
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: '#000', color: '#fff' }}>
              <TableRow>
                <TableCell style={{ color: '#fff', minWidth: '150px' }}>Code</TableCell>
                <TableCell style={{ color: '#fff', minWidth: '80px' }}>Status</TableCell>
                <TableCell style={{ color: '#fff', minWidth: '80px' }}>Symbol</TableCell>
                <TableCell style={{ color: '#fff', minWidth: '80px' }}>Seed</TableCell>
                <TableCell style={{ color: '#fff', minWidth: '80px' }}>Egg ID</TableCell>
                <TableCell style={{ color: '#fff', minWidth: '80px' }}>Pub ID</TableCell>
                <TableCell style={{ color: '#fff', minWidth: '150px' }}>Beneficiary</TableCell>
                <TableCell style={{ color: '#fff', minWidth: '150px' }}>Mana Value</TableCell>
                <TableCell style={{ color: '#fff', minWidth: '300px' }}>Mana Info</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCoupons.length > 0 && filteredCoupons.map((coupon) => (
                <TableRow key={coupon.couponCode} style={{ backgroundColor: coupon.id % 2 ? '#f5f5f5' : 'white' }}>
                  <TableCell>
                    {visibleCoupons[coupon.couponCode]
                      ? coupon.couponCode
                      : maskCouponCode(coupon.couponCode)}
                    <IconButton onClick={() => toggleCouponVisibility(coupon.couponCode)}>
		      {visibleCoupons[coupon.couponCode] ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </TableCell>
                  <TableCell>{coupon.status}</TableCell>
                  <TableCell>{coupon.symbol}</TableCell>
                  <TableCell>{coupon.seed}</TableCell>
                  <TableCell>{coupon.eggId}</TableCell>
                  <TableCell>{coupon.publisherId}</TableCell>
                  <TableCell>{coupon.beneficiary}</TableCell>
                  <TableCell>{coupon.manaValue}</TableCell>
                  <TableCell>{coupon.manaInfo}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ScrollableContainer>
    </Container>
  );
};

export default CouponList;

