import React, { useEffect, useState } from 'react';
import { Container, Typography, CssBaseline, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from '@mui/material';
import { getRequestLogs } from '../utils/api';
import { styled } from '@mui/material/styles';

const ScrollableContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
  overflowX: 'auto',
  width: '100%',
}));

const RequestLogList = () => {
  const [logs, setLogs] = useState([]);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const response = await getRequestLogs();
        setLogs(response.data.data);
        setFilteredLogs(response.data.data);
      } catch (error) {
        console.error('Error fetching logs:', error);
        setLogs([]);
        setFilteredLogs([]);
      }
    };

    fetchLogs();
  }, []);

  const handleFilterChange = (event) => {
    const keyword = event.target.value.toLowerCase();
    setFilter(keyword);

    const filtered = logs.filter(log => {
      return (
        log.user.toLowerCase().includes(keyword) ||
        log.endpoint.toLowerCase().includes(keyword) ||
        log.userId.toString().includes(keyword) ||
        log.statusCode.toString().includes(keyword) ||
        log.resultSummary.toLowerCase().includes(keyword)
      );
    });

    setFilteredLogs(filtered);
  };

  return (
    <Container component="main">
      <CssBaseline />
      <Typography component="h1" variant="h5" gutterBottom>
        リクエストログ一覧
      </Typography>
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        id="filter"
        label="検索"
        name="filter"
        autoComplete="filter"
        value={filter}
        onChange={handleFilterChange}
      />
      <Typography variant="body1">
        {filteredLogs.length} / {logs.length}
      </Typography>
      <ScrollableContainer>
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: '#000', color: '#fff' }}>
              <TableRow>
                <TableCell style={{ color: '#fff', minWidth: '200px' }}>Timestamp</TableCell>
                <TableCell style={{ color: '#fff', minWidth: '150px' }}>Request ID</TableCell>
                <TableCell style={{ color: '#fff', minWidth: '100px' }}>User ID</TableCell>
                <TableCell style={{ color: '#fff', minWidth: '150px' }}>User</TableCell>
                <TableCell style={{ color: '#fff', minWidth: '200px' }}>Endpoint</TableCell>
                <TableCell style={{ color: '#fff', minWidth: '100px' }}>Status Code</TableCell>
                <TableCell style={{ color: '#fff', minWidth: '300px' }}>Result Summary</TableCell>
                <TableCell style={{ color: '#fff', minWidth: '100px' }}>Duration (ms)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredLogs.length > 0 && filteredLogs.map((log, index) => (
                <TableRow key={index} style={{ backgroundColor: index % 2 ? '#f5f5f5' : 'white' }}>
                  <TableCell>{new Date(log.timestamp).toLocaleString()}</TableCell>
                  <TableCell>{log.requestId}</TableCell>
                  <TableCell>{log.userId}</TableCell>
                  <TableCell>{log.user}</TableCell>
                  <TableCell>{log.endpoint}</TableCell>
                  <TableCell>{log.statusCode}</TableCell>
                  <TableCell>{log.resultSummary}</TableCell>
                  <TableCell>{log.duration}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ScrollableContainer>
    </Container>
  );
};

export default RequestLogList;

