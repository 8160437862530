// src/utils/api.js
import axios from 'axios';
import { generateRequestId, generateRandomPassword } from './helpers';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(config => {
  const token = localStorage.getItem('accessToken');
  const requestId = generateRequestId();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.headers['request-id'] = requestId;

  return config;
});

export const getUsers = () => api.get('/admin/user/list');
export const registerUser = (username) => api.post('/admin/user/register', { username, password: generateRandomPassword() });

export const getCoupons = (start = 0, end = 10000) => api.get('/coupon/list', {
  params: { start, end }
});

export const generateCoupon = (symbol, seed, manaInfo, manaValue = 0 ) =>  api.post('/admin/coupon/generate', { symbol, seed, manaInfo, manaValue });

export const getRequestLogs = () => api.get('/admin/request-logs/latest') 

export const loginUser = (username, password) => api.post('/login', { username, password });

// 予算追加API
export const addBudget = (publisherId, amount) => api.post('/admin/budget/add', { publisherId, amount });

// 予算リスト取得API
export const getBudgetList = () => api.get('/admin/budget/list');


export default api;

